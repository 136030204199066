import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/arrow_select.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  class: "vSelect__arrow"
}
const _hoisted_2 = {
  key: 1,
  class: "vSelect__list"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 2,
  class: "vSelect__err"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    ref: _ctx.placeholder,
    class: _normalizeClass(["vSelect", [{ active: _ctx.drop }, { err: _ctx.error }]]),
    style: _normalizeStyle({ marginBottom: _ctx.marginBottom + 'px' })
  }, [
    (!_ctx.isSingleItem)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["vSelect__placeholder", { active: _ctx.data.length, disabled: _ctx.disabled }])
    }, _toDisplayString(_ctx.placeholder), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["vSelect__label", { '--empty-placeholder': !_ctx.placeholder }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.disabled && !_ctx.isSingleItem ? _ctx.toggleDrop() : null))
    }, _toDisplayString(_ctx.data ? _ctx.data : ''), 3),
    (!_ctx.isSingleItem && _ctx.drop)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item.id,
              class: _normalizeClass(["vSelect__item", { active: item.name === _ctx.data || item.id === _ctx.data }]),
              onClick: ($event: any) => (_ctx.selectValue(item.name, item.id))
            }, _toDisplayString(item.name), 11, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ], 6)), [
    [_directive_click_outside, _ctx.hideDrop]
  ])
}